import React from 'react'
import styles from './secondBlock2.module.scss'

const SecondBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  return (
    <div className={styles.secondPage} ref={props.reference}>
      <div className={styles.title}>Наши навыки, помогающие Вам добиваться результата</div>
      <div className={styles.cardsWrapper}>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Многообразие услуг</div>
          <div className={styles.cardDesc}>
            Предоставляем целый комплекс услуг в сфере IT: креативные, технические и аналитические решения.
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Лучшие специалисты</div>
          <div className={styles.cardDesc}>
            Более 170 квалифицированных <br /> IT- специалистов в штабе компании.
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Профессиональный опыт</div>
          <div className={styles.cardDesc}>
            Создаем высоконагруженные масштабируемые веб‑сервисы, построенные на современных технологиях и фреймворках.
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondBlock
