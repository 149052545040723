import React from 'react'
import { RefObject, useEffect, useState } from 'react'
import styles from './header.module.scss'
import TNLogo from '/src/assets/icons/tn-logo.svg'
import KSLogo from '/src/assets/icons/KSLogo2.svg'
import KSName from '/src/assets/icons/KSName.svg'
import KSDarkLogo from '/src/assets/icons/KSDarkLogo.svg'
import KSDarkName from '/src/assets/icons/KSDarkName.svg'
import TNName from '/src/assets/icons/tn-name.svg'
import TNKSLogo from '/src/assets/icons/KSandTnLogo.svg'
import MenuMobileWhite from '/src/assets/icons/menu-mobile.svg'
import MenuMobileBlack from '/src/assets/icons/menu-mobileBlack.svg'
import ArrowUp from '/src/assets/icons/arrowUp.svg'
import TNNameDark from '/src/assets/icons/tn-name-dark.svg'
import TNLogoDark from '/src/assets/icons/tn-logo-black.svg'
import KSLogoDark from '/src/assets/icons/ks-icon-dark.svg'
import MenuMobile from '../menuMobile/menuMobile'
import NewModalWindow from '../newModalWindow/newModalWindow'

interface IProps {
  refs: RefObject<HTMLDivElement>[]
  titles: string[]
  headerSize?: number
}

const Header = (props: IProps) => {
  const [showArrow, setShowArrow] = useState(false)
  const [scroll, setScroll] = React.useState<boolean>(false)
  const [menuMobile, setMenuMobile] = React.useState<boolean>(false)
  const [isModal, setIsModal] = React.useState<boolean>(false)

  const modalClick = () => {
    document.body.style.overflow = isModal
      ? (document.body.style.overflowY = 'unset')
      : (document.body.style.overflowY = 'hidden')
    setIsModal((previsModal) => !previsModal)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const scrollToRef = (ref: React.RefObject<any>) =>
    window.scrollTo({ top: ref.current.offsetTop - (props.headerSize || 0), behavior: 'smooth' })

  const handleScroll = () => {
    if (window.scrollY > 40) {
      setScroll(true)
    } else {
      setScroll(false)
    }
    if (window.scrollY > 800) {
      setShowArrow(true)
    } else {
      setShowArrow(false)
    }
  }

  const menuMobileClose = () => {
    document.body.style.overflow = menuMobile
      ? (document.body.style.overflowY = 'unset')
      : (document.body.style.overflowY = 'hidden')
    setMenuMobile((prevmenuMobile) => !prevmenuMobile)
  }

  return (
    <>
      {isModal ? <NewModalWindow isModal={isModal} modalClose={modalClick} /> : null}
      <MenuMobile refs={props.refs} titles={props.titles} menuMobile={menuMobile} menuMobileClose={menuMobileClose} />
      {showArrow && (
        <div className={styles.arrowToUp} onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
          <ArrowUp />
        </div>
      )}

      <div className={scroll ? styles.headerActive : styles.header}>
        <div className={styles.logos}>
          <div className={styles.TNLogo}>
            {scroll ? <TNLogoDark /> : <TNLogo />}
            {scroll ? <TNNameDark /> : <TNName />}
          </div>
          <div className={styles.KSLogo}>
            {scroll ? <KSDarkLogo /> : <KSLogo />}
            {scroll ? <KSDarkName /> : <KSName />}
          </div>
          <div className={styles.mobileLogo}>
            {scroll ? <TNKSLogo style={{ filter: 'invert(100%)' }} /> : <TNKSLogo />}
          </div>
        </div>
        <div className={styles.refsWrapper}>
          <div className={styles.ref} onClick={() => scrollToRef(props.refs[0])}>
            О школе
          </div>
          <div className={styles.ref} onClick={() => scrollToRef(props.refs[1])}>
            Курсы
          </div>
          <div className={styles.ref} onClick={() => scrollToRef(props.refs[2])}>
            Преподаватели
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <button className={styles.headButton} onClick={modalClick}>
            Записаться на курс
          </button>
          <div
            className={menuMobile ? styles.checkboxOff : styles.checkbox}
            onClick={() => {
              document.body.style.overflow = menuMobile
                ? (document.body.style.overflowY = 'unset')
                : (document.body.style.overflowY = 'hidden')
              setMenuMobile(!menuMobile)
            }}
          >
            {scroll ? <MenuMobileBlack /> : <MenuMobileWhite />}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
