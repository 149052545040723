import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Header from '../components/main-page/header/header'
import FirstBlock from '../components/main-page/firstBlock/firstBlock'
import SecondBlock from '../components/main-page/secondBlock/secondBlock'
import ThirdBlock from '../components/main-page/thirdBlock/thirdBlock'
import FourthBlock from '../components/main-page/fourthBlock/fourthBlock'
import FifthBlock from '../components/main-page/fifthBlock/fifthBlock'
// import SixthBlock from '../components/main-page/sixthBlock/sixthBlock'
import { useEffect, useRef } from 'react'
import NinthBlock from '../components/sys-analyst/ninthBlock/ninthBlock'
import * as poly from 'smoothscroll-polyfill'

const IndexPage = () => {
  const aboutSchool = useRef<HTMLDivElement>(null)
  const courses = useRef<HTMLDivElement>(null)
  const lectors = useRef<HTMLDivElement>(null)

  // const scrollToRef = (ref: React.RefObject<any>) => ref.current.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => poly.polyfill(), [])

  return (
    <Layout>
      <Header refs={[aboutSchool, courses, lectors]} titles={['О школе', 'Курсы', 'Преподаватели']} headerSize={80} />
      <FirstBlock />
      <SecondBlock reference={aboutSchool} />
      <ThirdBlock />
      <FourthBlock reference={courses} />
      <FifthBlock reference={lectors} />
      <NinthBlock />
      {/*<Footer />*/}
      <Seo title='Главная' />
    </Layout>
  )
}

export default IndexPage
