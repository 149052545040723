import React from 'react'
import styles from './firstBlock.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import Ellipse from './ellipse.svg'
import { Link } from 'gatsby'

const FirstBlock = () => {
  return (
    <div className={styles.firstPage}>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          Профессиональная <br /> школа подготовки <br /> IT-специалистов
        </div>
        <div className={styles.info}>Направления курсов:</div>
        <div className={styles.coursesWrapper}>
          <div className={styles.course}>
            <Link to={'/systems-analyst'} style={{ textDecoration: 'none' }}>
              Системный аналитик
            </Link>
          </div>
          <div className={styles.course}>
            <div>Java-разработчик</div>
            <div className={styles.soon}>скоро</div>
          </div>
          <div className={styles.course}>
            QA-инженер<div className={styles.soon}>скоро</div>
          </div>
          <div className={styles.course}>
            Python + Data Science<div className={styles.soon}>скоро</div>
          </div>
        </div>
      </div>

      <div className={styles.circleAnimationBlock}>
        <div className={styles.firstPageCircle} style={{ backgroundImage: `url(${Ellipse})` }}>
          {/*<StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />*/}
        </div>
        <div className={styles.firstPageCircle2} style={{ backgroundImage: `url(${Ellipse})` }}>
          {/*<StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />*/}
        </div>
      </div>
    </div>
  )
}

export default FirstBlock
