import React from 'react'
import styles from './fourthBlock.module.scss'
import NewModalWindow from '../newModalWindow/newModalWindow'
import { Link } from 'gatsby'
import card41 from './card4_1.svg'
import card81 from '../../sys-analyst/eighthBlock/card8_1.svg'
import card82 from '../../sys-analyst/eighthBlock/card8_2.svg'
import card83 from '../../sys-analyst/eighthBlock/card8_3.svg'

const FourthBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  const [isModal, setIsModal] = React.useState<boolean>(false)

  const modalClick = () => {
    console.log('kek', isModal)
    setIsModal((previsModal) => !previsModal)
  }

  return (
    <>
      {isModal ? <NewModalWindow isModal={isModal} modalClose={modalClick} /> : null}
      <div className={styles.fourthPage} ref={props.reference}>
        <div className={styles.cardsBlock}>
          <div className={styles.professionsBlock}>
            <div className={styles.professions}>
              <div className={styles.professionsTitle}>Профессии с трудоустройством</div>

              <div className={styles.professionsDesc}>
                Большие образовательные программы, чтобы освоить новую специальность с самого нуля. Во время обучения мы
                поможем вам найти работу.
              </div>

              <div className={styles.professionsButton}>
                <button className={styles.submitButton} onClick={modalClick}>
                  Записаться на курс
                </button>
              </div>
            </div>
          </div>

          <div className={styles.cards}>
            <div
              className={styles.card}
              style={{
                backgroundImage: `url(${card41})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left',
              }}
            >
              <div className={styles.main}>
                <div className={styles.cardTitle}>Системный аналитик</div>
                <div className={styles.cardDescription}>
                  Научитесь создавать продукт, удовлетворяющего нуждам и запросам заказчика.
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.block}>
                  <div className={styles.title}>Начало курса</div>
                  <div className={styles.subtext}>16 мая 2021</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Формат</div>
                  <div className={styles.subtext}>Онлайн</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Стоимость</div>
                  <div className={styles.subtext}>59 000 ₽</div>
                </div>
              </div>
            </div>
            <div
              className={styles.card}
              style={{
                backgroundImage: `url(${card81})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left',
              }}
            >
              <div className={styles.main}>
                <div className={styles.cardTitle}>QA-инженер</div>
                <div className={styles.cardDescription}>Вам доступны все лекции, трансляции и материалы курса</div>
              </div>
              <div className={styles.info}>
                <div className={styles.block}>
                  <div className={styles.title}>Начало курса</div>
                  <div className={styles.subtext}>16 мая 2021</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Формат</div>
                  <div className={styles.subtext}>Онлайн</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Стоимость</div>
                  <div className={styles.subtext}>59 000 ₽</div>
                </div>
              </div>
            </div>
            <div
              className={styles.card}
              style={{
                backgroundImage: `url(${card82})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left',
              }}
            >
              <div className={styles.main}>
                <div className={styles.cardTitle}>Java-разработчик</div>
                <div className={styles.cardDescription}>
                  Вы изучите язык Java, чтобы в командах с другими разработчиками создавать продукты наподобие
                  Яндекс.Музыки, Тинькофф-банка, Netflix и Кинопоиска.
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.block}>
                  <div className={styles.title}>Начало курса</div>
                  <div className={styles.subtext}>16 октября 2021</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Формат</div>
                  <div className={styles.subtext}>Онлайн</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Стоимость</div>
                  <div className={styles.subtext}>59 000 ₽</div>
                </div>
              </div>
            </div>
            <div
              className={styles.card}
              style={{
                backgroundImage: `url(${card83})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: '5%',
                backgroundPositionY: 'top',
              }}
            >
              <div className={styles.main}>
                <div className={styles.cardTitle}>Python + Data Science</div>
                <div className={styles.cardDescription}>
                  Мы хотим научить вас основным инструментам этой профессии: Python и его библиотекам, в том числе
                  Scikit-Learn и XGBoost, Jupyter Notebook, SQL.
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.block}>
                  <div className={styles.title}>Начало курса</div>
                  <div className={styles.subtext}>16 октября 2021</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Формат</div>
                  <div className={styles.subtext}>Онлайн</div>
                </div>
                <div className={styles.block}>
                  <div className={styles.title}>Стоимость</div>
                  <div className={styles.subtext}>59 000 ₽</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mobileProfessionsButton}>
          <button className={styles.submitButton} onClick={modalClick}>
            Записаться на курс
          </button>
        </div>
      </div>
    </>
  )
}

export default FourthBlock
