import React from 'react'
import styles from './thirdBlock.module.scss'
import InfoLogo from '/src/assets/icons/info-logo.svg'
import StarLogo from '/src/assets/icons/star-logo.svg'
import SquareLogo from '/src/assets/icons/square-logo.svg'
import SaturnLogo from '/src/assets/icons/saturn-logo.svg'

const ThirdBlock = () => {
  return (
    <div className={styles.thirdPage}>
      <div className={styles.titleThird}>
        <p>Что мы предлагаем</p>
      </div>
      <div className={styles.content}>
        <div className={styles.contentItem}>
          <InfoLogo className={styles.contentImg} />
          <div className={styles.contentTitle}>
            <p>Только нужная</p>
            <p>информация</p>
          </div>
          <p className={styles.contentDescription}>
            Максимально практичные курсы без «воды» и теорией, которая точно пригодится в работе
          </p>
        </div>
        <div className={styles.contentItem}>
          <div className={styles.contentImg}>
            <StarLogo className={styles.starImg} />
          </div>

          <div className={styles.contentTitle}>
            <p>Востребованные</p>
            <p>навыки</p>
          </div>

          <p className={styles.contentDescription}>
            Максимально практичные курсы без «воды» и теорией, которая точно пригодится в работе
          </p>
        </div>
        <div className={styles.contentItem}>
          <SquareLogo className={styles.contentImg} />
          <div className={styles.contentTitle}>
            <p>Преподаватели</p>
            <p> с 10-летним стажем</p>
          </div>

          <div className={styles.contentDescription}>
            Программы, которые мы предлагаем, пользуются популярностью в любой IT- компании
          </div>
        </div>
        <div className={styles.contentItem}>
          <SaturnLogo className={styles.contentImg} />
          <div className={styles.contentTitle}>
            <p>Стажировка</p>
            <p>в компании</p>
          </div>

          <div className={styles.contentDescription}>
            Программы, которые мы предлагаем, пользуются популярностью в любой IT- компании
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdBlock
