import React from 'react'
import styles from './fifthBlock.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import SliderComponent from '../slider/aliceCarousel'

const responsive = {
  0: { items: 1 },
  1200: { items: 1 },
  1202: { items: 2 },
  1910: { items: 2 },
  1024: { items: 2 },
}

const javaDev = [
  <div className={styles.reader}>
    <div className={styles.photo}>
      <StaticImage src='./1.jpg' alt='photo' />
    </div>
    <div className={styles.name}>Дмитрий Снимщиков</div>
    <div className={styles.info}>Ведущий Java-разработчик в ГК «Технологии Надёжности»</div>
    <div className={styles.author}>Автор курса «Java-разработчик»</div>
  </div>,
  <div className={styles.reader}>
    <div className={styles.photo}>
      <StaticImage src='./2.jpg' alt='photo' />
    </div>
    <div className={styles.name}>Роман Немыкин</div>
    <div className={styles.info}>Ведущий Java-разработчик в ГК «Технологии Надёжности»</div>
    <div className={styles.author}>Автор курса «Java-разработчик»</div>
  </div>,
  <div className={styles.reader} style={{ paddingRight: '0' }}>
    <div className={styles.photo}>
      <StaticImage src='./3.jpg' alt='photo' />
    </div>
    <div className={styles.name}>Дмитрий Торопчин</div>
    <div className={styles.info}>Ведущий Java-разработчик в ГК «Технологии Надёжности»</div>
    <div className={styles.author}>Автор курса «Java-разработчик»</div>
  </div>,
]

const systemAnalys = [
  <div className={styles.reader}>
    <div className={styles.photo}>
      <StaticImage src='./photo.svg' alt='photo' />
    </div>
    <div className={styles.name}>Дарья Чернышева</div>
    <div className={styles.info}>Ведущий системный аналитик в ГК «Технологии Надёжности»</div>
    <div className={styles.author}>Автор курса «Системный аналитик»</div>
  </div>,
  <div className={styles.reader}>
    <div className={styles.photo}>
      <StaticImage src='./photo.svg' alt='photo' />
    </div>
    <div className={styles.name}>Елена Гусева</div>
    <div className={styles.info}>Ведущий системный аналитик в ГК «Технологии Надёжности»</div>
    <div className={styles.author}>Автор курса «Системный аналитик»</div>
  </div>,
]

const renderDotsItem = ({ isActive }) => {
  return isActive ? <div className={styles.lineBlue} /> : <div className={styles.lineGray} />
}

const FifthBlock = (props: { reference: React.RefObject<HTMLDivElement> }) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const [nextSlideEnabled, setNextSlideEnabled] = React.useState<boolean>(false)
  const [prevSlideEnabled, setPrevSlideEnabled] = React.useState<boolean>(true)
  const [currentItems, setCurrentItems] = React.useState<JSX.Element[]>(javaDev)

  const slidePrev = () => setActiveIndex((activeIndex) => Math.min(activeIndex - 1, 0))
  const slideNext = () => setActiveIndex((activeIndex) => Math.max(activeIndex + 1, 1))
  const setNewItems = (items: JSX.Element[]) => {
    setCurrentItems(items)
    setActiveIndex(0)
    setPrevSlideEnabled(true)
    setNextSlideEnabled(false)
  }

  return (
    <div className={styles.fifthPage}>
      <div className={styles.firstBlock}>
        <div className={styles.firstBlockGrid}>
          <div className={styles.leftPart}>
            <div className={styles.text}>Как вы найдете работу с нашей помощью</div>
          </div>

          <div className={styles.rightPart}>
            <div className={styles.rightPartRow}>
              <div className={styles.rowTitle}>Дадим необходимые знания</div>
              <div className={styles.rowDesc}>
                Вы получите навыки на курсах основного обучения и факультативах от компаний-партнёров.
              </div>
            </div>
            <div className={styles.rightPartRow}>
              <div className={styles.rowTitle}>Предложим подходящие вакансии</div>
              <div className={styles.rowDesc}>
                Вы будете рассматривать предложенные нами позиции, откликаться на вакансии из раздела «Карьера» и
                участвовать в поиске идеального места работы.
              </div>
            </div>
            <div className={styles.rightPartRow3}>
              <div className={styles.rowTitle}>Поможем получить работу мечты</div>
              <div className={styles.rowDesc}>
                Мы делаем всё, чтобы студенты нашли новую работу: выдаем официальный диплом, учим собирать портфолио
                проектов, помогаем с поиском работы.
              </div>
            </div>
          </div>
        </div>

        <div className={styles.middleBlock}>
          <div className={styles.middleBlockGrid}>
            <div className={styles.middleLeft}>
              <div className={styles.imgBlockMobile}>
                <StaticImage src='./diplomas.png' alt='diplomas' />
              </div>
              <div className={styles.middleText}>
                <div className={styles.middleTitle}>
                  Диплом об окончании <br /> курса
                </div>

                <div className={styles.middleDesc}>
                  В конце обучения вы выполните выпускную курсовую работу и получите диплом государственного образца
                </div>
              </div>
            </div>

            <div className={styles.middleRight}>
              <div className={styles.imgBlock}>
                <StaticImage src='./diplomas.png' alt='diplomas' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.secondBlock}>
        <div className={styles.secondBlockGrid}>
          <div className={styles.secondLeftPart}>
            <div className={styles.title} ref={props.reference}>
              Преподаватели
            </div>
            <div className={styles.chooseCourse}>
              <div
                onClick={() => setNewItems(javaDev)}
                className={currentItems === javaDev ? styles.groupSelected : styles.group}
              >
                Java-разработка
              </div>
              <div
                onClick={() => setNewItems(systemAnalys)}
                className={currentItems === systemAnalys ? styles.groupSelected : styles.group}
              >
                Системная аналитика
              </div>
              <div>QA-инженерия</div>
              <div>Python + Data Science</div>
            </div>
          </div>
          <div className={styles.secondRightPart}>
            <div className={styles.buttons}>
              <button className={styles.leftButton} onClick={slidePrev} disabled={prevSlideEnabled}>
                <StaticImage src='./arrowLeft.svg' alt='arrowLeft' />
              </button>

              <button className={styles.rightButton} onClick={slideNext} disabled={nextSlideEnabled}>
                <StaticImage src='./arrowRight.svg' alt='arrowRight' />
              </button>
            </div>
            <div className={styles.container}>
              {/*<SliderComponent>{currentItems.map((i) => i)}</SliderComponent>*/}
              <AliceCarousel
                autoWidth
                renderDotsItem={renderDotsItem}
                items={currentItems}
                activeIndex={activeIndex}
                responsive={responsive}
                disableButtonsControls
                onSlideChanged={(e) => {
                  console.log(e)
                  setNextSlideEnabled(e.isNextSlideDisabled)
                  setPrevSlideEnabled(e.isPrevSlideDisabled)
                }}
              />

              <div className={styles.buttonsMobile}>
                <button className={styles.leftButtonMobile} onClick={slidePrev} disabled={prevSlideEnabled}>
                  <StaticImage src='./arrowLeft.svg' alt='arrowLeft' />
                </button>

                <button className={styles.rightButtonMobile} onClick={slideNext} disabled={nextSlideEnabled}>
                  <StaticImage src='./arrowRight.svg' alt='arrowRight' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FifthBlock
